<template>
  <div class="courseNumReport">
    <div class="search" style="display: block">
      <a-tree-select
        class="search-style"
        style="width: 300px"
        v-model="OrgIds"
        :tree-data="treeData"
        tree-checkable
        :maxTagCount="1"
        placeholder="请选择搜索范围"
        @change="change"
        :show-checked-strategy="SHOW_PARENT"
      />
      <!--:show-checked-strategy="SHOW_PARENT"-->
      <a-select
        class="search-style"
        @change="handleChange"
        style="width: 200px"
        v-if="class_category"
        placeholder="课程类型"
      >
        <a-select-option value="">全部</a-select-option>
        <a-select-option v-for="item in class_category" :value="item.Id">
          {{ item.Name }}
        </a-select-option>
      </a-select>
      <a-range-picker
        :value="createValue"
        class="search-style"
        style="width: 200px"
        format="YYYY-MM-DD"
        :placeholder="['开始时间', '结束时间']"
        allowClear
        @change="ChangeSearchTime"
      />

      <a-button class="search-style" @click="_clear">重置</a-button>
      <a-button type="primary" @click="_search">查询</a-button>
    </div>
    <div v-if="data" style="width:1500px;overflow: auto">
      <a-table
        :pagination="false"
        :columns="columns"
        bordered
        :data-source="data"
        :scroll="{ x: 2400, y: 500 }"
      >
        <div slot="key" slot-scope="text, record, index">
          <a-icon
            style="font-size: 18px;margin-right: 8px;color: #3aa1ff;"
            @click="_source(text, record)"
            type="pie-chart"
          />
          <a-icon
            style="font-size: 18px;color: #8a7bd4;"
            type="line-chart"
            @click="_container(text, record)"
          />
        </div>
      </a-table>
    </div>

    <div v-show="spinning" style="position:fixed;left:50%;">
      <a-spin style="margin: 150px 0%;">
        <a-icon
          slot="indicator"
          type="loading"
          :spinning="spinning"
          style="font-size: 44px;color:red;"
          spin
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import G2 from '@antv/g2'
// import { TreeSelect } from 'ant-design-vue';

export default {
  name: 'courseNumReport',
  data () {
    return {
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      chartData: [
        { genre: 'Sports', sold: 275 },
        { genre: 'Strategy', sold: 115 },
        { genre: 'Action', sold: 120 },
        { genre: 'Shooter', sold: 350 },
        { genre: 'Fight', sold: 150 },
        { genre: 'Narration', sold: 23 },
        { genre: 'Race', sold: 223 },
        { genre: 'Advanture', sold: 57 },
        { genre: 'Puzzle', sold: 109 },
        { genre: 'Music', sold: 555 }
      ],
      columns: [
        {
          title: '中心',
          dataIndex: 'OrgName',
          key: 'OrgName',
          align: 'left',
          width: 260,
          fixed: 'left'
        },
        {
          title: '班级数',
          dataIndex: 'Class_Count',
          key: 'Class_Count',
          align: 'center',
          width: 120
        },
        {
          title: '人数',
          dataIndex: 'StudentId_Filter_Count',
          key: 'StudentId_Filter_Count',
          align: 'center',
          width: 80
        },
        {
          title: '阶段数据',
          children: [
            {
              title: 'S1',
              dataIndex: 'Stage_S1',
              key: 'Stage_S1',
              width: 80
            },
            {
              title: 'S2',
              dataIndex: 'Stage_S2',
              key: 'Stage_S2',
              width: 80
            },
            {
              title: 'S3',
              dataIndex: 'Stage_S3',
              key: 'Stage_S3',
              width: 80
            },
            {
              title: 'S4',
              dataIndex: 'Stage_S4',
              key: 'Stage_S4',
              width: 80
            },
            {
              title: 'S5',
              dataIndex: 'Stage_S5',
              key: 'Stage_S5',
              width: 80
            },
            {
              title: 'S6',
              dataIndex: 'Stage_S6',
              key: 'Stage_S6',
              width: 80
            },
            {
              title: 'S7',
              dataIndex: 'Stage_S7',
              key: 'Stage_S7',
              width: 80
            },
            {
              title: 'S8',
              dataIndex: 'Stage_S8',
              key: 'Stage_S8',
              width: 80
            }
          ]
        },
        {
          title: '上课进度',
          children: [
            {
              title: '1岁',
              dataIndex: 'Class_Age_Progress_1',
              key: 'Class_Age_Progress_1',
              width: 80
            },
            {
              title: '2岁',
              dataIndex: 'Class_Age_Progress_2',
              key: 'Class_Age_Progress_2',
              width: 80
            },
            {
              title: '3岁',
              dataIndex: 'Class_Age_Progress_3',
              key: 'Class_Age_Progress_3',
              width: 80
            },
            {
              title: '4岁',
              dataIndex: 'Class_Age_Progress_4',
              key: 'Class_Age_Progress_4',
              width: 80
            },
            {
              title: '5岁',
              dataIndex: 'Class_Age_Progress_5',
              key: 'Class_Age_Progress_5',
              width: 80
            },
            {
              title: '6岁',
              dataIndex: 'Class_Age_Progress_6',
              key: 'Class_Age_Progress_6',
              width: 80
            },
            {
              title: '7岁',
              dataIndex: 'Class_Age_Progress_7',
              key: 'Class_Age_Progress_7',
              width: 80
            },
            {
              title: '8岁',
              dataIndex: 'Class_Age_Progress_8',
              key: 'Class_Age_Progress_8',
              width: 80
            }
          ]
        },
        {
          title: '实际年龄',
          children: [
            {
              title: '1岁',
              dataIndex: 'Real_Age_1',
              key: 'Real_Age_1',
              width: 80
            },
            {
              title: '2岁',
              dataIndex: 'Real_Age_2',
              key: 'Real_Age_2',
              width: 80
            },
            {
              title: '3岁',
              dataIndex: 'Real_Age_3',
              key: 'Real_Age_3',
              width: 80
            },
            {
              title: '4岁',
              dataIndex: 'Real_Age_4',
              key: 'Real_Age_4',
              width: 80
            },
            {
              title: '5岁',
              dataIndex: 'Real_Age_5',
              key: 'Real_Age_5',
              width: 80
            },
            {
              title: '6岁',
              dataIndex: 'Real_Age_6',
              key: 'Real_Age_6',
              width: 80
            },
            {
              title: '7岁',
              dataIndex: 'Real_Age_7',
              key: 'Real_Age_7',
              width: 80
            },
            {
              title: '8岁',
              dataIndex: 'Real_Age_8',
              key: 'Real_Age_8'
            }
          ]
        }
      ],
      data: '',
      startSignDate: '',
      endSignDate: '',
      spinning: false,
      visibleName: '',
      visible: false,
      visible2: false,
      createValue: [],
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      treeData: [
        {
          title: 'Node1',
          value: '7520669f3b20008c6a6df71e9cebc2f9',
          key: '3',
          children: [
            {
              title: 'Child Node1',
              value: '7520669f3b20008c6a6df71e9cebc2f944',
              key: '4'
            }
          ]
        },
        {
          title: 'Node2',
          value: '6',
          key: '7',
          children: [
            {
              title: 'Child Node3',
              value: '7520669f3b20008c6a6df71e9cebc2f9sdfs',
              key: '8'
            },
            {
              title: 'Child Node4',
              value: '7520669f3b20008c6a6df71e9cebc2f9wee',
              key: '9'
            },
            {
              title: 'Child Node5',
              value: '7520669f3b20008c6a6df71e9cebc2f9weffds',
              key: '1'
            }
          ]
        }
      ],

      OrgIds: [],
      OrgName: [],
      ParentId: [],
      SHOW_PARENT: 'SHOW_ALL',
      class_category: '',
      classTypeId: ''
    }
  },
  created () {
    this._treeData()
    this.loadData()
    this._class_category()
  },
  methods: {
    _class_category () {
      let self = this
      this.$axios.get(1103, {}, res => {
        if (res.data.code == 1) {
          self.class_category = res.data.data
        }
      })
    },
    _treeData () {
      let self = this
      this.$axios.get(
        1090,
        {
          UserId: self.userinfo.uid
        },
        res => {
          self.treeData = res.data
          console.log(res)
        }
      )
    },
    change (value, label, text) {
      if (text.checked) {
        this.ParentId.push(text.triggerValue)
      } else {
        if (this.ParentId.length > 0) {
          for (var i = 0; i < this.ParentId.length; i++) {
            if (text.triggerValue == this.ParentId[i]) {
              this.ParentId.splice(i, 1)
            }
          }
        }
      }

      this.OrgName = label
      this.OrgIds = value
    },
    handleChange (value) {
      console.log(value)
      this.classTypeId = value
    },
    loadData () {
      this.spinning = true
      let self = this
      this.$message.loading('加载中...')
      this.$axios.post(
        1100,
        {
          startSignDate: self.startSignDate,
          endSignDate: self.endSignDate,
          OrgIds: self.OrgIds.length > 0 ? JSON.stringify(self.OrgIds) : '',
          UserId: self.userinfo.uid,
          OrgName: self.OrgName.length > 0 ? JSON.stringify(self.OrgName) : '',
          ParentId:
            self.ParentId.length > 0 ? JSON.stringify(self.ParentId) : '',
          classTypeId: self.classTypeId
        },
        res => {
          self.spinning = false
          console.log(res)
          // if(res.data.columns){
          //     self.columns = res.data.columns;
          // }

          self.data = res.data

          this.$message.destroy()
        }
      )
    },
    _search () {
      this.loadData()
    },
    _clear () {
      this.classTypeId = ''
      this.OrgIds = []
      this.OrgName = []
      this.createValue = []
      this.startSignDate = ''
      this.endSignDate = ''
      this.ParentId = []
      this.loadData()
    },
    ChangeSearchTime (time, timeString) {
      this.createValue = time
      this.startSignDate = timeString[0]
      this.endSignDate = timeString[1]
    }
  }
}
</script>

<style scoped lang="less">
.courseNumReport {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}
.search {
  margin-bottom: 20px;
  .search-style {
    display: inline-block;
    width: 120px;
    margin-right: 15px;
  }
}
</style>
